import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/Layout';
import Loader from '../../components/loader/Loader';

function New() {
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState('');
  const [mob, setMob] = useState('');
  const [referer, setReferer] = useState('');

  useEffect(() => {
    fetch('https://api.kanikaplayhub.com/get-referer')
      .then(response => response.json())
      .then(data => {
        setReferer(data.referer);
        // console.log(data);
      })
      .catch(error => console.error('Error fetching referer:', error));
  }, []);

  const decodeBase64 = (input) => {
    try {
      return atob(input);
    } catch (e) {
      console.error('Invalid Base64 string');
      return '';
    }
  };

  const url_string = window.location.href;
  const url = new URL(url_string);
  const encodedData = url.searchParams.get("data");

  const decoded = decodeBase64(encodedData);

  const params = new URLSearchParams(decoded);
  const pay = params.get('pay');
  const orderId = params.get("order");
  const description = params.get("description");
  const paramName = params.get("name"); // Renamed to avoid conflict
  const paramMob = params.get("mob"); // Renamed to avoid conflict

  useEffect(() => {
    if (pay && orderId && description && paramName && paramMob) {
      handlePayment(pay, description, orderId, paramName, paramMob);
    }
  }, [pay, orderId, description, paramName, paramMob]);

  const handlePayment = (pay, description, orderId, name, mob) => {
  const options = {
    key: 'rzp_live_KTDUkB8xlMRTNg', // Replace with your Razorpay key ID
    amount: pay * 100, // Amount in paise
    currency: 'INR',
    name: name,
    description: description,
    order_receipt: `order_rcptid_${name}`,
    handler: async function (response) {
      try {
        // Verify the payment immediately after the transaction
        const verifyResponse = await fetch('https://api.kanikaplayhub.com/api/verifyPayment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            razorpay_payment_id: response.razorpay_payment_id,
            mob: mob,
            amount: pay,
          }),
        });
        
        const verifyData = await verifyResponse.json();

        if (verifyData.status === 'captured') {
          setSuccessMessage('Payment successful! Your order has been placed.\nclose this tab or auto closed');
          setTimeout(() => {
            window.close();
          }, 10);
        } else {
          setSuccessMessage('Payment verification failed. Please contact support.');
        }
      } catch (error) {
        setSuccessMessage('Error verifying payment. Please try again.');
      }
      setLoading(false);
    },
    prefill: {
      name: name,
      email: 'user@example.com',
      contact: mob,
    },
    notes: {
      orderId: orderId,
      address: 'Razorpay Corporate Office',
    },
    theme: {
      color: '#F37254',
    },
  };

  const rzp1 = new Razorpay(options);
  setTimeout(() => {
    rzp1.open();
  }, 10);
};

  const handleTabClose = () => {
    window.close();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout>
      {successMessage ? (
        <div className="text-center">
          <div className="text-center text-2xl text-green-500">{successMessage}</div>
          <button onClick={handleTabClose} className="mt-4 text-blue-500 underline">
            Click to close tab
          </button>
        </div>
      ) : (
        <h2 className="text-center text-2xl text-white">No Order</h2>
      )}
    </Layout>
  );
}

export default New;
