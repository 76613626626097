import React, { useContext } from 'react';
import myContext from '../../context/data/myContext';
import { Link } from 'react-router-dom';

function Footer() {
  const context = useContext(myContext);
  const { mode } = context;

  return (
    <div>
      <footer className={`text-gray-600 body-font ${mode === 'dark' ? 'bg-gray-800 text-white' : 'bg-gray-300'}`}>
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap md:text-left text-center order-first">
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className={`title-font font-medium tracking-widest text-sm mb-3 ${mode === 'dark' ? 'text-white' : 'text-gray-900'}`}>CATEGORIES</h2>
              <nav className="list-none mb-10">
                <li>
                  <Link to="/" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Home</Link>
                </li>
                <li>
                  <Link to="/order" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Order</Link>
                </li>
                <li>
                  <Link to="/cart" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Cart</Link>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className={`title-font font-medium tracking-widest text-sm mb-3 uppercase ${mode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Customer Service</h2>
              <nav className="list-none mb-10">
                <li>
                  <Link to="/returnpolicy" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Return Policy</Link>
                </li>
                <li>
                  <Link to="/about" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>About Us</Link>
                </li>
                <li>
                  <Link to="/contact" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Contact Us</Link>
                </li>
                <li>
                  <Link to="/term-condition" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/faq" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>FAQ</Link>
                </li>
                <li>
                  <Link to="/delivery-info" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Delivery Information</Link>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className={`title-font font-medium tracking-widest text-sm mb-3 ${mode === 'dark' ? 'text-white' : 'text-gray-900'}`}>Services</h2>
              <nav className="list-none mb-10">
                <li>
                  <Link to="/privacypolicy" className={`hover:text-gray-800 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`}>Privacy Policy</Link>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <img src="/src/assets/pay.png" alt="Payment Options" />
            </div>
          </div>
        </div>

        <div className={`bg-gray-200 ${mode === 'dark' ? 'bg-gray-700' : 'bg-gray-200'} text-gray-700`}>
          <div className="container px-5 py-3 mx-auto flex items-center sm:flex-row flex-col">
            <Link to="/" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <span className="ml-3 text-xl">Kanika Play Hub</span>
            </Link>
            <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2023 Kanika Play Hub —
              <a href="https://kanikaplayhub.com/" className={`ml-1 ${mode === 'dark' ? 'text-white' : 'text-gray-600'}`} target="_blank" rel="noopener noreferrer">kanikaplayhub.com</a>
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a className={`text-gray-500 ${mode === 'dark' ? 'text-white' : 'text-gray-500'}`}>
                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
                </svg>
              </a>
              <a className={`ml-3 ${mode === 'dark' ? 'text-white' : 'text-gray-500'}`}>
                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
                </svg>
              </a>
              <a className={`ml-3 ${mode === 'dark' ? 'text-white' : 'text-gray-500'}`}>
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
                  <rect width={20} height={20} x={2} y={2} rx={5} ry={5} />
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
                </svg>
              </a>
              <a className={`ml-3 ${mode === 'dark' ? 'text-white' : 'text-gray-500'}`}>
                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0} className="w-5 h-5" viewBox="0 0 24 24">
                  <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" />
                  <circle cx={4} cy={4} r={2} stroke="none" />
                </svg>
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
